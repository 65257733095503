<template>
  <v-sheet
    v-if="!formule.length"
    :color="`grey`"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="text-field, table, data-table"
    >
    </v-skeleton-loader>
  </v-sheet>
  <v-card
    v-else
    class="app-invoice-editable"
  >
    <v-snackbar
      v-model="snackbar.show"
      :vertical="true"
      :timeout="3000"
      :color="snackbar.color"
      top
      right
      transition="scale-transition"
    >
      {{ snackbar.message }}
    </v-snackbar>
    <!--    <snack-view-->
    <!--      :message=""-->
    <!--      :activate=""-->
    <!--      :bg-color=""-->
    <!--    ></snack-view>-->

    <v-card-title>
      Formulaire d'enregistrement {{ !isContratFlotte?'des locaux':'des collaborateurs' }}
    </v-card-title>
    <v-divider></v-divider>

    <v-card-subtitle v-if="!isPrepaye">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <span><b class="font-weight-bold text-uppercase">Sous-total:</b> {{ withCurrency(sousTotal) }} </span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="4"
        >
          <span><b class="font-weight-bold">Coef =</b> {{ bond.coeficientBond }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="4"
        >
          <span><b class="font-weight-bold text-uppercase">DEPOSIT:</b> {{ withCurrency(caution) }}</span>
        </v-col>
        <!--        <v-spacer></v-spacer>-->
        <!--        <v-col-->
        <!--          cols="12"-->
        <!--          md="2"-->
        <!--        >-->
        <!--          <span><b class="font-weight-bold text-uppercase">Discount:</b> {{ bond.bonusActivationParameters }}%</span>-->
        <!--        </v-col>-->
        <!--        <v-spacer></v-spacer>-->
        <!--        <v-col-->
        <!--          cols="12"-->
        <!--          md="3"-->
        <!--        >-->
        <!--          <span><b class="font-weight-bold text-uppercase">Net à payer:</b> {{ withCurrency(totalToPay) }}</span>-->
        <!--        </v-col>-->
      </v-row>
    </v-card-subtitle>
    <v-divider class="mb-n5"></v-divider>
    <!-- Add With Excell File -->
    <div class="add-products-form pt-9 pb-10 px-8">
      <div
        v-if="!isApiCanalAvailable"
        class="col-md-12"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <label
              v-if="!isContratFlotte"
              for="file"
            >Ajouter des locaux et décodeurs via le fichier Excel</label>
            <label
              v-else
              for="file"
            >Ajouter des collaborateurs via le fichier Excel</label>
            <v-file-input
              ref="fileXcel"
              v-model="xlFile"
              :accept="filesAccepted"
              label="Fichier Excel"
              outlined
              dense
              :rules="LocalNameRules"
              class="p-0 mt-1"
              @input="loadXlFile"
              @click:clear="clearConfig"
            >
              <v-icon slot="prependIcon">
                {{ icons.mdiFileDocumentOutline }}
              </v-icon>
            </v-file-input>
          </v-col>
        </v-row>
        <!--            <input-->
        <!--              class="form-control d-inline-block"-->
        <!--              type="file"-->
        <!--              @change=""-->
        <!--            >-->
        <xlsx-read :file="xlFile">
          <!--          <xlsx-sheets>-->
          <!--            <template #default="{sheets}">-->
          <!--              <div>-->
          <!--                {{ sheets }}-->
          <!--                {{ getLeafs(sheets) }}-->
          <!--              </div>-->
          <!--            </template>-->
          <!--          </xlsx-sheets>-->
          <xlsx-json
            sheet="Feuil1"
            @parsed="changed"
          >
          </xlsx-json>
        </xlsx-read>
      </div>

      <div
        v-for="(purchasedProduct, purchasedProductIndex) in data.locals"
        :key="purchasedProductIndex"
        class="single-product-form repeater-form"
      >
        <v-form
          ref="localForm"
          class="multi-col-validation"
          @submit.prevent="submitForm"
        >
          <div class="pl-4 pr-4 pb-5">
            <v-expansion-panels
              v-model="panelDecodeur"
              multiple
              focusable
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="primary--text">{{ !isContratFlotte?'Configuration de l\'emplacement':'Collaborateur' }} &nbsp;<b>{{ purchasedProduct.LocalName }}</b></span>
                  <template v-slot:actions>
                    <v-btn
                      v-if="purchasedProductIndex !=0"
                      v-ripple="{ class: 'error--text' }"
                      text
                      small
                      class="remove-hover"
                      @click="data.locals.splice(purchasedProductIndex, 1)"
                    >
                      <v-icon color="error">
                        {{ icons.mdiCloseCircle }}
                      </v-icon> <span class="error--text">{{ !isContratFlotte?'Supprimer l\'emplacement':'Supprimer le collaborateur' }}</span>
                    </v-btn>
                    <v-icon color="primary">
                      $expand
                    </v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- bloc du local-->
                  <v-row class="ml-2 mr-1">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-if="isContratFlotte"
                        v-model.number="typeCLientSelected"
                        outlined
                        dense
                        :items="typeCLient"
                        item-text="name"
                        item-value="id"
                        :menu-props="{ offsetY : true }"
                        placeholder="Abonné canal+ ?"
                        label="Abonné canal+ ?"
                        hide-details="auto"
                        class="mb-3"
                        @input="obj => updateItemForm(purchasedProductIndex, obj)"
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-row class="col-md-12 mt-2">
                    <v-row class="ml-2 mr-1">
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model.trim="purchasedProduct.LocalName"
                          :label="`${!isContratFlotte?'Nom de  l\'emplacement':'Nom du collaborateur'}`"
                          :rules="LocalNameRules"
                          outlined
                          dense
                          :disabled="isContratFlotte && typeCLientSelected===1?true:false"
                          :placeholder="`${!isContratFlotte?'nom de l\'emplacement':'nom du collaborateur'}`"
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-select
                          v-if="!isContratFlotte"
                          v-model.number="purchasedProduct.LocalType"
                          outlined
                          dense
                          :rules="LocalNameRules"
                          :items="typeLocal"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ offsetY : true }"
                          placeholder="Sélectionnez le type"
                          label="Type d'emplacement"
                          hide-details="auto"
                          class="mb-3"
                          @input="obj => updateItemForm(purchasedProductIndex, obj)"
                        ></v-select>
                        <v-text-field
                          v-else
                          v-model.number="purchasedProduct.phone"
                          outlined
                          dense
                          :disabled="isContratFlotte && typeCLientSelected===1?true:false"
                          :rules="LocalNameRules"
                          :menu-props="{ offsetY : true }"
                          placeholder="numéro de téléphone"
                          label="Numéro de téléphone"
                          hide-details="auto"
                          class="mb-3"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <!-- bloc pour le nouveau décodeur-->

                    <div
                      v-for="(decodeur, decodeurIndex) in purchasedProduct.Decodeur"
                      :key="decodeurIndex"
                      class="col-md-12"
                    >
                      <v-expansion-panels
                        v-model="panelDecodeur"
                        multiple
                        focusable
                      >
                        <v-expansion-panel
                          active-class="pane-active"
                        >
                          <v-expansion-panel-header>
                            <span class="primary--text">Création du décodeur <b class="blue">{{ decodeur.Numero }}</b></span>
                            <template v-slot:actions>
                              <!--                              <v-btn-->
                              <!--                                v-if="decodeurIndex !=0"-->
                              <!--                                v-ripple="{ class: 'error&#45;&#45;text' }"-->
                              <!--                                text-->
                              <!--                                small-->
                              <!--                                @click="purchasedProduct.Decodeur.splice(decodeurIndex, 1)"-->
                              <!--                              > -->
                              <v-btn
                                v-ripple="{ class: 'error--text' }"
                                text
                                small
                                @click="purchasedProduct.Decodeur.splice(decodeurIndex, 1)"
                              >
                                <v-icon color="error">
                                  {{ icons.mdiCloseCircle }}
                                </v-icon> <span class="error--text">Supprimer</span>
                              </v-btn>
                              <v-icon color="primary">
                                $expand
                              </v-icon>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-row class="ml-6 mr-6">
                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-text-field
                                  v-model.number="decodeur.Numero"
                                  label="Numéro du décodeur"
                                  :rules="LocalNumberRules"
                                  type="number"
                                  outlined
                                  dense
                                  placeholder="Numéro du décodeur"
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-text-field
                                  v-model="decodeur.Emplacement"
                                  label="Emplacement"
                                  :rules="LocalNameRules"
                                  outlined
                                  dense
                                  placeholder="Emplacement"
                                  hide-details="auto"
                                ></v-text-field>
                              </v-col>

                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-select
                                  v-model="decodeur.Formule"
                                  :rules="LocalNameRules"
                                  outlined
                                  dense
                                  :items="formule"
                                  item-text="label"
                                  item-value="code"
                                  :menu-props="{ offsetY : true }"
                                  placeholder="Sélectionnez la formule"
                                  label="Formule du décodeur"
                                  hide-details="auto"
                                  class="mb-3"
                                  @input="obj => updateItemForm(purchasedProductIndex, obj)"
                                ></v-select>
                              </v-col>

                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-select
                                  v-model="decodeur.Option"
                                  :rules="LocalNameRules"
                                  outlined
                                  dense
                                  :items="option"
                                  item-text="nom"
                                  item-value="code"
                                  :menu-props="{ offsetY : true }"
                                  placeholder="Sélectionnez l'option"
                                  label="Option du décodeur"
                                  hide-details="auto"
                                  class="mb-3"
                                  @input="obj => updateItemForm(purchasedProductIndex, obj)"
                                ></v-select>
                              </v-col>

                              <v-col
                                cols="12"
                                md="6"
                              >
                                <v-menu
                                  v-model="decodeur.menu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="auto"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      label="Date de fin d'abonnement"
                                      :rules="LocalNameRules"
                                      :value="formatDate(decodeur.DateFin)"
                                      hide-details="auto"
                                      persistent-hint
                                      :readonly="true"
                                      outlined
                                      dense
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <template v-slot:append>
                                        <v-icon>{{ icons.mdiCalendarMonth }}</v-icon>
                                      </template>
                                    </v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="decodeur.DateFin"
                                    color="primary"
                                    locale="pr-fr"
                                    @input="decodeur.menu = false"
                                  ></v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </div>
                    <div
                      v-if="isApiCanalAvailable && (!isContratFlotte || (isContratFlotte && typeCLientSelected===1))"
                      class="col-md-12"
                    >
                      <v-card style="border: 1px solid #ccc6c6">
                        <v-card-text>
                          <v-card-text class="primary--text">
                            Recherche de décodeur
                          </v-card-text>
                          <v-divider></v-divider>

                          <v-row class="ml-6 mr-6 mt-2">
                            <div>
                              <snack-view
                                :message="snackbar.message"
                                :activate="snackbar.active"
                                :bg-color="snackbar.color"
                              ></snack-view>
                              <div
                                v-if="purchasedProduct.submited==true"
                                class="d-flex align-center"
                              >
                                <v-progress-circular
                                  :size="100"
                                  :width="7"
                                  color="primary"
                                  indeterminate
                                ></v-progress-circular>
                              </div>
                              <div
                                v-else
                                class="py-6 mb-4"
                              >
                                <v-form
                                  ref="searchForm"
                                  v-model="purchasedProduct.searchForm"
                                >
                                  <v-row class="mx-auto">
                                    <v-col
                                      cols="12"
                                      md="5"
                                    >
                                      <v-select
                                        v-model="purchasedProduct.recherche.type"
                                        outlined
                                        dense
                                        :rules="LocalNameRules"
                                        :items="typeRecherche"
                                        item-value="id"
                                        item-text="name"
                                        :menu-props="{ offsetY : true }"
                                        placeholder="Type de recherche"
                                        label="Type de recherche"
                                        hide-details="auto"
                                        return-object
                                        @change="changePrefix(purchasedProductIndex)"
                                      ></v-select>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="4"
                                    >
                                      <v-text-field
                                        v-model="purchasedProduct.recherche.value"
                                        label="valeur de la recherche"
                                        :rules="LocalNameRules"
                                        outlined
                                        dense
                                        placeholder="valeur de la recherche"
                                        hide-details="auto"
                                        :prefix="purchasedProduct.recherche.type.prefix"
                                      ></v-text-field>
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      md="3"
                                    >
                                      <v-btn
                                        color="primary"
                                        @click="searchDecodeur(purchasedProductIndex)"
                                      >
                                        <v-icon>fas fa-search</v-icon> Rechercher
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-form>
                              </div>
                              <v-dialog
                                v-model="dialog"
                                persistent
                              >
                                <v-card>
                                  <v-card-title>
                                    <span>  Résultat de la recherche</span>
                                  </v-card-title>
                                  <v-divider></v-divider>
                                  <v-data-table
                                    v-model="decoderSelected"
                                    :headers="headersDetails"
                                    :items="decodeurs"
                                    :items-per-page="-1"
                                    sort-by="id"
                                    show-select
                                    :disable-pagination="true"
                                    :hide-default-footer="true"
                                    @toggle-select-all="selectAllToggle"
                                  >
                                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                                      <v-simple-checkbox
                                        :value="isSelected"
                                        :readonly="item.disabled"
                                        :disabled="item.disabled"
                                        @input="select($event)"
                                      ></v-simple-checkbox>
                                    </template>

                                    <template #[`item.actions`]="{ item }">
                                      <div class="col-12">
                                        <v-btn
                                          outlined
                                          color="success"
                                          class="mt-1"
                                          :disabled="item.state===-1"
                                        >
                                          <v-icon
                                            small
                                            class="mr-2"
                                          >
                                            fas fa-plus
                                          </v-icon>
                                          Ajouter
                                        </v-btn>
                                      </div>
                                    </template>
                                  </v-data-table>
                                  <v-divider></v-divider>
                                  <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      class="mt-4"
                                      color="error"
                                      outlined
                                      @click="close"
                                    >
                                      Annuler
                                    </v-btn>
                                    <v-btn
                                      class="mt-4"
                                      color="primary"
                                      @click="validate(purchasedProductIndex)"
                                    >
                                      Valider
                                    </v-btn>
                                  </v-card-actions>
                                </v-card>
                              </v-dialog>
                            </div>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </div>
                    <!-- bouton d'ajout du décodeur-->
                    <v-card-text class="mt-4 text-right">
                      <v-btn
                        color="primary"
                        x-small
                        text
                        @click="addNewDecodeur(purchasedProductIndex)"
                      >
                        Ajouter un Décodeur  <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-form>
      </div>
      <v-btn
        color="primary"
        text
        class="mt-5"
        @click="addNewLocal"
      >
        <v-icon>{{ icons.mdiPlusCircle }}</v-icon> {{ !isContratFlotte?'Ajouter un emplacement':'Ajouter un collaborateur' }}
      </v-btn>
    </div>

    <v-divider></v-divider>

    <!-- boutton de validation du formulaire-->
    <v-row class="ml-6 mr-6 mt-4 text-right">
      <v-col cols="12">
        <v-btn
          type="reset"
          outlined
          class="mx-2"
          @click="resetForm"
        >
          Annuler
        </v-btn>
        <v-btn
          v-if="isVisu()"
          color="primary"
          :disabled="loading"
          @click="submitForm"
        >
          Créer
        </v-btn>
      </v-col>
    </v-row>
    <div
      v-if="loading"
      class="pst-center d-flex justify-center"
    >
      <v-progress-circular
        color="primary"
        class="align-self-center"
        indeterminate
        :size="100"
        :width="7"
      ></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import {
  computed, nextTick, onBeforeMount, reactive, ref,
} from '@vue/composition-api'
import {
  mdiCloseCircle, mdiCogOutline, mdiCalendarMonth, mdiPlusCircle, mdiFileDocumentOutline,
} from '@mdi/js'
import Http from '@/helpers/http'
import router from '@/router'
// eslint-disable-next-line import/extensions
import SnackView from '@/views/SnackView'
// eslint-disable-next-line import/no-extraneous-dependencies
import XlsxJson from 'vue-xlsx/dist/components/XlsxJson'
// eslint-disable-next-line import/no-extraneous-dependencies
import XlsxRead from 'vue-xlsx/dist/components/XlsxRead'
// eslint-disable-next-line import/no-extraneous-dependencies
import XlsxTable from 'vue-xlsx/dist/components/XlsxTable'
// eslint-disable-next-line import/no-extraneous-dependencies
import moment from 'moment'
import Vue from 'vue'
import { COEF } from '@/helpers/caution'
import {
  is_category_flotte,
  LOCAL_CONTRAT_CATEGORY_TYPE,
  PREPAYE_TYPE,
} from '@/helpers/constants/contrat'
import Session from '@/helpers/session'
import useDynamicConstant from '@/helpers/useDynamicConstant'
import { isVisu } from '@/helpers/constants/roles'

Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
    XlsxRead,
    XlsxJson,
    // eslint-disable-next-line vue/no-unused-components
    XlsxTable,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props, { refs }) {
    const isIssueDateMenuOpen = ref(false)
    const isDueDateMenuOpen = ref(false)
    const localForm = ref(null)
    const typeLocal = ref([])
    const snackbar = ref({
      show: false,
      message: '',
      color: 'success',
      timeout: 2000,
    })
    const indexLocal = 0
    const selectedSheet = ref(null)
    const sheets = ref([])
    let xlFile = ref(null)

    const { apiCanalAvailability } = useDynamicConstant()

    const isApiCanalAvailable = ref(0)

    apiCanalAvailability(resp => {
      isApiCanalAvailable.value = resp
    })

    const loadXlFile = () => {
      // xlFile.value = e.target.files ? e.target.files[0] : null
      loading.value = true
    }

    const fileXcel = ref(null)
    const clearConfig = () => {
      // eslint-disable-next-line no-use-before-define
      loading.value = true
      // eslint-disable-next-line no-param-reassign
      props.data.locals = [
        {
          LocalName: '',
          LocalType: null,
          recherche: { type: '', value: '' },
          submited: false,
          Decodeur: [
            // {
            //   Numero: null,
            //   Emplacement: '',
            //   Formule: '',
            //   Option: '',
            //   DateFin: null,
            //   menu: '',
            //   menu2: '',
            // },
          ],
        },
      ]

      nextTick(() => {
        console.log('xlFile.value')
        xlFile = null
        // eslint-disable-next-line no-param-reassign
        refs.fileXcel.resetValidation()
        localForm.value[0].resetValidation()
        // eslint-disable-next-line no-use-before-define
        loading.value = false
      })
    }

    const isPrepaye = computed(() => PREPAYE_TYPE === JSON.parse(localStorage.getItem('defaultContrat')).customer_type_id)

    const formule = ref([])

    const option = ref([{
      alpha2: 'CM',
      code: 'AUCUNE',
      id: 0,
      nom: 'Aucune',
      pu: 0,
    }])

    const formatDate = itemDate => {
      if (itemDate !== null) {
        return moment(itemDate, 'YYYY/MM/DD').format('DD/MM/YYYY')
      }
    }

    const updateItemForm = (index, val) => {
      const { cost, hours, description } = val
      /* eslint-disable no-param-reassign */
      props.data.locals[index].cost = cost
      props.data.locals[index].hours = hours
      props.data.locals[index].description = description
      /* eslint-enable */
    }

    // Type de fichier accepté par le champ Reçu
    const filesAccepted = [
      '.xlsx', '.xls',
    ]

    const leafs = ref([])

    const getLeafs = leafItems => {
      leafs.value = leafItems
    }

    const addNewLocal = () => {
      props.data.locals.push(
        {
          LocalName: '',
          LocalType: null,
          recherche: { type: '', value: '' },
          submited: false,
          Decodeur: [
            // {
            //   Numero: null,
            //   Emplacement: '',
            //   Formule: '',
            //   Option: '',
            //   DateFin: null,
            //   menu: '',
            //   menu2: '',
            // },
          ],
        },
      )
    }

    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]

    const LocalNumberRules = [
      v => !!v || 'Ce champ est réquis',
      v => /[0-9]{12,14}/.test(v) || 'Ce numéro doit contenir au moins 12 chiffres',
      // eslint-disable-next-line no-restricted-globals,no-mixed-operators
      v => (!isNaN(parseFloat(v))) && v > 0 || 'Veuillez entrer un nombre positif',
      v => !(existDecodeur(v) > 1) || 'Ce décodeur a été renseigné plusieurs fois',
    ]

    const nomMoyenPaiement = moyenCode => {
      const name = meansOfPayment.value.filter(m => m.code === moyenCode)
      if (name.length > 0) return name[0].label

      return ''
    }

    const existDecodeur = numero => {
      for (let i = 0; i < props.data.locals.length; i++) {
        const decodeur = props.data.locals[i].Decodeur.filter(m => m.Numero === numero)
        if (decodeur.length > 1) {
          console.log('numero', decodeur.length)

          return decodeur.length
        }
      }

      console.log('numero rien', 0)

      return 0
    }

    const submitForm = () => {
      // validation du formulaire
      // console.log(localForm.value.validate())

      // const isFormValid = localForm.value.validate()
      // if (!isFormValid) return
      // soumission des données
      const a = []
      localForm.value.forEach(form => {
        a.push(form.validate())
      })

      // eslint-disable-next-line radix

      if (!a.includes(false)) {
        loading.value = true
        Http.post(
          'local',
          props.data,
        ).then(rep => {
          console.log(rep.data)
          snackbar.value.message = 'Enregistrement effectué avec succès'
          snackbar.value.color = 'success'
          snackbar.value.show = true
          loading.value = false
          setTimeout(() => {
            router.push({ name: 'list-local' })
          }, 3000)
        }).catch(error => {
          // console.error(error)
          loading.value = false
        })
      }
    }

    const { CURRENCY } = useDynamicConstant()

    const currency = ref(CURRENCY)

    const withCurrency = price => {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(price)) {
        return 0 + CURRENCY
      }

      return `${Number(price).toLocaleString()} ${CURRENCY}`
    }

    const addNewDecodeur = localIndex => {
      if (localIndex === -1) {
        // eslint-disable-next-line no-param-reassign
        localIndex = (props.data.locals.length - 1)
      }
      props.data.locals[localIndex].Decodeur.push(
        {
          Numero: '',
          Emplacement: '',
          Formule: '',
          Option: '',
          DateFin: null,
          menu: '',
          menu2: '',
        },
      )
    }

    const loadFormules = () => {
      Http.get('get-formules')
        .then(async response => {
          formule.value = await response.data
        })
        .catch(error => { console.error('Erreur :', error) })
    }

    const loadOptions = () => {
      Http.get('get-options')
        .then(response => {
          option.value = [...option.value, ...response.data]
        })
        .catch(error => { console.error('Erreur :', error) })
    }

    const resetForm = () => {
      localForm.value.reset()
    }

    const imunition = ref([])

    const codeFormule = labelFormule => {
      if (labelFormule == undefined) {
        return ''
      }
      const code = formule.value.filter(i => (i.label).toUpperCase() === (labelFormule).toUpperCase())

      return (code.length) ? code[0].code : ''
    }

    const codeOption = labelOption => {
      if (labelOption == undefined) {
        return 'Aucune'
      }
      const code = option.value.filter(i => (i.nom).toUpperCase() === (labelOption).toUpperCase())

      return (code.length) ? code[0].code : ''
    }

    const getIdTypeLocal = typeLabel => {
      if (typeLabel == undefined) {
        return ''
      }
      const id = typeLocal.value.filter(i => (i.name).toUpperCase() === (typeLabel).toUpperCase())

      return (id.length) ? id[0].id : ''
    }

    const changed = params => {
      // eslint-disable-next-line no-use-before-define
      loading.value = true
      snackbar.value.show = false
      snackbar.value.message = ''
      // eslint-disable-next-line no-use-before-define
      imunition.value = params
      console.log('imunition')
      console.log(imunition.value)

      if (imunition.value !== null) {
        const tab = []
        if (imunition.value[0] !== undefined) {
          if (imunition.value[0].LOCAL !== undefined) {
            imunition.value.forEach(i => {
              if (i.NUMERO_DECODEUR && i.EMPLACEMENT && i.LOCAL) {
                // setTimeout(() => {
                console.log(i)

                // console.log(props.data.locals[0].Decodeur[0].Numero === null)

                // eslint-disable-next-line no-shadow
                let indexLocal = null
                if (tab.length === 0) {
                  tab.push(i.LOCAL)
                }

                if (tab.indexOf(i.LOCAL) === -1 && tab.length > 0) {
                  addNewLocal()
                  tab.push(i.LOCAL)
                  indexLocal = tab.length - 1
                } else {
                  indexLocal = tab.indexOf(i.LOCAL)
                }

                // if (props.data.locals[indexLocal].Decodeur[0].Numero === null) {
                //   props.data.locals[indexLocal].LocalName = i.LOCAL
                //   props.data.locals[indexLocal].LocalType = getIdTypeLocal(i.TYPE)
                //   props.data.locals[indexLocal].Decodeur[0].Numero = i.NUMERO_DECODEUR
                //   props.data.locals[indexLocal].Decodeur[0].Emplacement = i.EMPLACEMENT
                //   props.data.locals[indexLocal].Decodeur[0].Formule = codeFormule(i.FORMULE)
                //   props.data.locals[indexLocal].Decodeur[0].Option = codeOption(i.OPTION)
                //   props.data.locals[indexLocal].Decodeur[0].DateFin = moment(i.DATE_FIN_ABONNEMENT, 'DD-MM-YYYY').format('YYYY-MM-DD')
                // } else {
                props.data.locals[indexLocal].Decodeur.push(
                  {
                    Numero: i.NUMERO_DECODEUR,
                    Emplacement: i.EMPLACEMENT,
                    Formule: codeFormule(i.FORMULE),
                    Option: codeOption(i.OPTION),
                    DateFin: moment(i.DATE_FIN_ABONNEMENT, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  },
                )

                // }

                console.log(props.data.locals[indexLocal].Decodeur)

                // }, 3000)
              }
            })
          } else {
            snackbar.value.message = 'Fichier invalide'
            snackbar.value.color = 'error'
          }
        } else {
          snackbar.value.message = 'Fichier invalide'
          snackbar.value.color = 'error'
        }
      }
      loading.value = false

      if (snackbar.value.message !== '') {
        snackbar.value.color = 'error'
        snackbar.value.show = true
      }
    }

    const sousTotal = computed(() => {
      let total = 0
      props.data.locals.forEach(i => {
        i.Decodeur.forEach(si => {
          if (si.Numero !== null && si.Option !== '' && si.Formule !== '') {
            // eslint-disable-next-line camelcase
            const m_option = option.value.filter(o => o.code === si.Option)
            // eslint-disable-next-line camelcase
            const m_formule = formule.value.filter(f => f.code === si.Formule)

            // eslint-disable-next-line radix
            total += (parseInt(m_formule[0].pu) + parseInt(m_option[0].pu))
          }
        })
      })

      return total
    })

    const bond = ref({})
    const getDeposit = () => {
      Http.get(`get-deposit-payment-information/${JSON.parse(localStorage.getItem('defaultContrat')).id}`)
        .then(response => {
          if (response.status === 200) {
            bond.value = response.data

            bond.value.amountBond = Math.ceil(bond.value.amountBond)
            bond.value.netPayable = Math.ceil(bond.value.netPayable)

            console.log('caution information', response.data)

            // console.log(bond.value)
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const isContratFlotte = ref(is_category_flotte())

    const loading = ref(false)

    const caution = computed(() => Math.ceil(sousTotal.value * COEF))

    const totalToPay = computed(() => (caution - (caution.value * 0.03)))

    onBeforeMount(() => {

    })

    /*
    * Partie recherche des abonnées via l'API
    * */

    const dialog = ref(false)

    const searchForm = ref(null)

    const decoderSelected = ref([])

    const decodeurs = []

    const recherche = []

    const prefix = ''

    const submited = false

    const typeRecherche = [

      {
        id: 2,
        name: 'Numéro de décodeur',
        code: 'numdecabo',
        prefix: '',
      },
      {
        id: 1,
        name: 'Numéro de téléphone',
        code: 'phone',
        prefix: '00237',
      },
      {
        id: 3,
        name: 'Numéro d\'abonné',
        code: 'numabo',
        prefix: '',
      },
    ]

    const typeCLientSelected = 1

    return {
      imunition,
      isIssueDateMenuOpen,
      isDueDateMenuOpen,
      localForm,
      loading,
      existDecodeur,
      fileXcel,
      typeLocal,
      updateItemForm,
      addNewLocal,
      addNewDecodeur,
      formule,
      option,
      isPrepaye,
      submitForm,
      formatDate,
      LocalNameRules,
      LocalNumberRules,
      loadFormules,
      loadOptions,
      panelLocal: [0],
      panelDecodeur: [0],
      indexLocal,
      getDeposit,
      // eslint-disable-next-line no-undef
      currency,
      // eslint-disable-next-line no-undef
      withCurrency,
      resetForm,
      xlFile,
      loadXlFile,
      changed,
      selectedSheet,
      sheets,
      sousTotal,
      caution,
      totalToPay,
      bond,
      isApiCanalAvailable,
      codeFormule,

      // snackbar
      snackbar,

      isContratFlotte,

      leafs,
      filesAccepted,
      getLeafs,
      clearConfig,

      // partie recherche de décodeurs

      decodeurs,
      recherche,
      typeRecherche,
      searchForm,
      dialog,
      decoderSelected,
      prefix,
      submited,
      disabledCount: 0,
      headersDetails: [
        { text: 'N°d\'abonné', value: 'numabo', divider: true },
        { text: 'Décodeur', value: 'clabo', divider: true },
        { text: 'Offre', value: 'optionmajeureabo', divider: true },
        { text: 'Propriétaire', value: 'nomabo', divider: true },
        { text: 'Ville', value: 'villabo', divider: true },
        { text: 'Date de fin', value: 'finabo', divider: true },

        // { text: 'Date d\'activation', value: 'date', divider: true },
        // { text: 'Action', value: 'actions', sortable: false, divider: true, },
      ],

      typeCLient: [{ id: 1, name: 'Oui' }, { id: 2, name: 'Non' }],
      typeCLientSelected,
      isVisu,

      // Icons
      icons: {
        mdiCloseCircle,
        mdiCogOutline,
        mdiCalendarMonth,
        mdiPlusCircle,
        mdiFileDocumentOutline,
      },
    }
  },
  mounted() {
    this.getLocalType()

    this.loadFormules()
    this.loadOptions()
  },
  methods: {
    getLocalType() {
      Http.get('local-type')
        .then(response => {
          this.typeLocal = response.data
          this.getDeposit()
        })
        .catch(error => console.log(error))
    },
    searchDecodeur(index) {
      const donnees = this.recherche

      console.log(donnees)

      console.log(index)
      console.log(this.$props.data.locals[index].recherche)
      const rechValue = this.$props.data.locals[index].recherche
      const rech = this.searchForm

      const v = []

      rech.forEach(form => {
        v.push(form.validate())
      })

      console.log('v', v)

      if (!v.includes(false)) {
        this.$props.data.locals[index].submited = true

        const param = { [rechValue.type.code]: rechValue.type.prefix + rechValue.value }

        Http.post('get-detail-contrat', param).then(rep => {
          this.$props.data.locals[index].submited = false
          console.log(rep)

          const { data } = rep

          this.decodeurs = []
          this.decoderSelected = []

          if (data.length) {
            this.disabledCount = 0
            for (let i = 0; i < data.length; i++) {
              let disabled = false
              for (let j = 0; j < this.$props.data.locals.length; j++) {
                const locaux = this.$props.data.locals[j]
                const number = data[i].subscriber.numdecodeur
                const val = locaux.Decodeur.filter(i => parseInt(i.Numero) === parseInt(number))
                console.log('data[i].subscriber', number)
                console.log('Decodeur', locaux.Decodeur)
                if (val.length) {
                  disabled = true
                  this.disabledCount += 1
                }
              }
              this.decodeurs.push({
                id: i + 1,
                clabo: data[i].subscriber.numdecodeur,
                nomabo: data[i].subscriber.nomabo,
                numabo: data[i].subscriber.numabo,
                optionmajeureabo: data[i].contract.offresLibelle,
                prenomabo: data[i].subscriber.prenomabo,
                villabo: data[i].subscriber.villecgaabo,
                phone: data[i].subscriber.mobile1,
                finabo: data[i].contract.finabo,
                disabled,
              })
            }
            this.dialog = true
          }
        }).catch(error => {
          this.$props.data.locals[index].submited = false
          console.log(error)
        })
      } else {
        // this.snack.active = true
        // this.snack.color = "error"
        // this.snack.message = "Veuillez remplir le formulaire convenablement"
      }
    },

    validate(localIndex) {
      this.close()

      console.log(this.decoderSelected)

      if (localIndex === -1) {
        // eslint-disable-next-line no-param-reassign
        localIndex = (props.data.locals.length - 1)
      }

      if (this.decoderSelected.length) {
        const dec = this.decoderSelected[0]
        this.$props.data.locals[localIndex].LocalName = `${dec.nomabo} ${dec.prenomabo}`
        this.$props.data.locals[localIndex].phone = dec.phone
      }

      console.log(this.$props.data.locals)
      for (let i = 0; i < this.decoderSelected.length; i++) {
        this.$props.data.locals[localIndex].Decodeur.push(
          {
            Numero: this.decoderSelected[i].clabo,
            Emplacement: '',
            Formule: this.codeFormule(this.decoderSelected[i].optionmajeureabo),
            Option: '',
            DateFin: moment(this.decoderSelected[i].finabo, 'DD/MM/YYYY').format('YYYY-MM-DD'),
            menu: '',
            menu2: '',
          },
        )
      }
    },

    close() {
      this.dialog = false
      this.decodeurs = []
    },

    selectAllToggle(props) {
      if (this.decoderSelected.length !== (this.decodeurs.length - this.disabledCount)) {
        this.decoderSelected = []
        const self = this
        props.items.forEach(item => {
          if (!item.disabled) {
            self.decoderSelected.push(item)
          }
        })
      } else this.decoderSelected = []
    },

    changePrefix(index) {
      console.log(this.$props.data.locals[index])

      this.prefix = this.$props.data.locals[index].recherche.type.prefix
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

.app-invoice-editable {
  .input-salesperson {
    width: 100px;
  }

  .select-invoice-to {
    width: 190px !important;
  }

  .header-inputs {
    width: 122px;
  }

  .add-products-form {
    .single-product-form {
      &:not(:first-child) {
        margin-top: 2rem;
      }
    }

    .header-spacer {
      // This is according to item actions width
      width: 39px;
    }
    .item-actions {
      @at-root {
        @include theme--child(add-products-form) using ($material) {
          .item-actions {
            border-left: thin solid map-deep-get($material, 'dividers');
          }
        }
      }
    }
  }
}
.blue{
  color: #2366ec;
}
v-expansion-panel{
  border-width: 1px !important;
  border-color: #000000;
}

.red{
  color: red;
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}

.pane {
  padding: 10px !important;
  min-height: 40px !important;
  background-color: #EEEEEE;
}

.pane-active {
  background-color: transparent;
}

button.v-expansion-panel-header.v-expansion-panel-header--active{
  padding: 10px !important;
  min-height: 40px !important;
  background-color: #EEEEEE;
}
.remove-hover .v-btn::before {
  background-color: transparent !important;
}
</style>
