<template>
  <v-row class="app-invoice-preview">
    <v-col
      cols="12"
      md="12"
    >
      <invoice-editable :data="locaux">
      </invoice-editable>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSendOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import InvoiceEditable from '../LocalEditable.vue'

export default {
  components: {
    InvoiceEditable,
  },
  setup() {
    // eslint-disable-next-line no-param-reassign
    const contract = JSON.parse(localStorage.getItem('defaultContrat')).id

    const locaux = ref({
      contractId: contract,
      locals: [
        {
          LocalName: '',
          LocalType: null,
          recherche: { type: '', value: '' } ,
          submited: false ,
          Decodeur: [
            // {
            //   Numero: null,
            //   Emplacement: '',
            //   Formule: '',
            //   Option: '',
            //   DateFin: null,
            //   menu: '',
            //   menu2: '',
            // },
          ],
        },
      ],
    })

    return {
      locaux,

      // Icons
      icons: {
        mdiSendOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

</style>
